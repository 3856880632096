<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>姓名：</span>
            <input v-model="pageInfo.name" placeholder="姓名" class="searchInput" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">管理员列表</div>
          <div><button class="addBtn" @click="showEdit('')"> + 新增</button></div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="ID" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column label="角色">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.type==1?'超级管理员':'普通管理员'}}
            </template>
          </el-table-column>
          <el-table-column prop="username" label="用户名" />
          <el-table-column prop="phone" label="手机" />
          <el-table-column prop="department" label="部门" />
          <el-table-column prop="time" label="创建时间" />
          <el-table-column label="监控查看权限" >
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              {{scope.row.hasVideoAuth ? '可查看' : '不可查看'}}
            </template>
          </el-table-column>
          <el-table-column label="权限">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #999999" v-if="scope.row.type==1">所有权限</el-button>
              <el-button link type="primary" v-else style="color: #3769B9" @click="powerEdit(scope.row)">权限分配</el-button>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="handleDelete(scope.row)">删除</el-button>
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
              <el-button link type="primary" style="color: #3769B9" @click="showMonitor(scope.row)">监控权限</el-button>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="密码" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="resetPassword(scope.row.id)">重置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <editDiv v-model="dialogFormVisible" :Row="Info" @closeDiv="closeDiv" @getList="getList" ref="editDiv"></editDiv>
    <el-dialog v-model="monitorFormVisible"
               title="监控权限编辑"
               destroy-on-close
               class="info-div600"
               style="padding: 24px;text-align: left">
      <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
        <el-form-item label="短信验证码" prop="verificationCode" label-width="120px">
          <el-row class="widthFull">
            <el-col :span="12">
              <el-input v-model="pageForm.verificationCode" autocomplete="off" placeholder="请输入"/>
            </el-col>
            <el-col :offset="1" :span="6">
              <el-button @click="getCode" :loading="getCodeLoading">
                <span>获取验证码</span>
                <span v-show="getCodeLoading">({{ loadingCountdown }})</span>
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="监控权限" prop="check" label-width="120px">
          <el-radio-group v-model="pageForm.check">
            <el-radio :label="true">可查看监控</el-radio>
            <el-radio :label="false">不查看监控</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer marginTop30px text-right">
        <button class="subBtn" @click="submitMonitor">提交</button>
        <button class="qxBtn" @click="monitorFormVisible = false">取消</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import editDiv from "@/view/adminUser/adminEdit.vue"
import {getAdminPage, queryMessageCode, resetUserPassword, setVideoAuth} from "@/api/api";
import {editQuestionType, setQuestionType} from "@/api/examination";
export default {
  name: "studentList",
  components:{
    editDiv,
  },
  data(){
    return{
      tableData:[],
      dataInfo:[],
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        name:'',
        page:1,
        total:0
      },
      exportIds:'',
      //
      monitorFormVisible: false,
      pageForm:{
        // 是否有权限
        check: false,
        // 验证码
        verificationCode: '',
      },
      pageFormRules: {
        verificationCode: [ {required: true, message: '请填写短信验证码', trigger: 'blur'}, ],
        check: [ {required: true, message: '请选择用户权限', trigger: 'blur'}, ],
      },
      getCodeLoading: false,
      loadingCountdown: 60,
      monitorUserRowId: 0,
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    /**
     * 获取短信验证码
     */
    getCode(){
      if (this.getCodeLoading) {
        return
      }

      const countdownHandler = () => {
        this.getCodeLoading = true
        this.timer = setInterval(() => {
          if (this.loadingCountdown === 0) {
            clearInterval(this.timer)
            this.loadingCountdown = 60
            this.getCodeLoading = false
            return
          }
          this.loadingCountdown -= 1
        }, 1000)
      }

      queryMessageCode().then((res) => {
        // 是否成功
        if(res.success){
          countdownHandler()
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    /**
     * 提交
     */
    submitMonitor(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          const params = {
            code: this.pageForm.verificationCode,
            hasAuth: this.pageForm.check,
            userId: this.monitorUserRowId
          }
          setVideoAuth(params).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.monitorFormVisible = false
                this.getList()
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    cancelPermissions(){

    },
    // -----------------
    reset(){
      this.pageInfo.name=''
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getAdminPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    powerEdit(row){
      let data='';
      if(row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({path:"/adminUser/userPower",query:{info:data}});
    },
    showEdit(row){
      this.dialogFormVisible=true;
      this.Info=row;
      let data='';
      if(row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data);//调用子组件方法
    },
    /**
     *
     * @param row
     */
    showMonitor(row){
      this.monitorUserRowId = row.id
      //
      this.pageForm = {
        check: false,
        verificationCode: '',
      }
      //
      this.monitorFormVisible = true
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    resetPassword(id){
      this.$root.ElMessageBox.confirm(
          '你确定要重置该用户的密码吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
          }
      ).then(() => {
        resetUserPassword(id).then(res=>{
          if(res.success){
            this.$root.ElMessage({type:'success',message:res.message});
          }else{
            this.$root.ElMessage.error(res.message);
          }
        })
      })
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    // 删除
    handleDelete() {
      this.$root.ElMessageBox.confirm(
          '你确定要删除吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
          }
      ).then(() => {
        this.$message.error('主平台已绑定，不能删除')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>
