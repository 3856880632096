<template>
    <el-dialog title="管理员信息编辑" class="info-div600" style="padding: 24px;text-align: left">
      <el-form label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="Info.username" autocomplete="off" :disabled="Info.id?true:false" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="密码"  v-if="!Info.id">
          <el-input v-model="Info.password" autocomplete="off" type="password" placeholder="密码需8-18位包含数字大小写字母字符串"/>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="Info.type" filterable placeholder="请选择一个角色" class="room-select-tl">
            <el-option label="普通管理员" :value="0"/><el-option label="超级管理员" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="Info.name" autocomplete="off" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="Info.phone" autocomplete="off" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="部门">
          <el-input v-model="Info.department" autocomplete="off" placeholder="请输入部门" />
        </el-form-item>
      </el-form>
    <div class="dialog-footer marginTop30px text-right">
      <button class="subBtn" @click="subData">提交</button>
      <button class="qxBtn" @click="closeDiv">取消</button>
    </div>
  </el-dialog>
</template>
<script>
import {addAdmin} from "@/api/api";
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info: {id: '', name: '', code: '', username: '', type: 0, department: ''},
      content:['']
    }
  },
  mounted() {
  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    getInfo(row){//清空表单
      if(row){
        this.Info=JSON.parse(row);
      }else{
        this.Info={};
      }
    },
    changeDic(index,type){
      if(type=='add'){
        this.content.push('');
      }else if(type=='reced'){
        this.content.splice(index,1);
      }
    },
    subData(){//提交
      if(!this.Info.username){
        this.$root.ElMessage.error('用户名不能为空！');
        return false;
      }
      let method='post';
      if(this.Info.id){method='put';}
      addAdmin(this.Info,method).then((res)=>{
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message});
          this.closeDiv();
          this.$emit('getList');
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleAvatarSuccess(e){//图片上传成功后
      this.Info.businessLicenseImg=e.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.child_Dic{
  .icon_dic_css{font-size: 20px;margin-left: 10px;color: #cccccc}
}
</style>